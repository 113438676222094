import { Helmet } from "react-helmet"
import styled from "styled-components"

import { MainLayout } from "../../layouts/MainLayout"
import { getSeoTitle } from "../../seo"
import { palette } from "../../theme/pallette"
import { Container } from "../../uikit/Container"

export const H1 = styled.h1`
  margin-top: 10rem;
  text-align: center;
`

export const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>{getSeoTitle("404")}</title>
      </Helmet>

      <MainLayout>
        <Container bgColor={palette.white}>
          <H1>404 Not found</H1>
        </Container>
      </MainLayout>
    </>
  )
}
