import { useState } from "react"
import { up } from "styled-breakpoints"
import styled from "styled-components"

import { BlockTitle } from "../../uikit/BlockTitle"
import { Button } from "../../uikit/Button"
import { Container } from "../../uikit/Container"
import { palette } from "../../theme/pallette"

import check from "./assets/check-circle.svg"
import checkWhite from "./assets/check-circle-white.svg"
import card from "./assets/card.svg"

import { periodEnum } from "./enums"
import { Switcher } from "./Switcher"

const Wrapper = styled.div`
  padding: 3rem 0;

  ${up("md")} {
    padding: 5rem 0;
  }
`

export const ItemWrapper = styled.div`
  background-color: ${palette.white};
  color: ${palette.black};
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  border-radius: 0.5rem;
  text-align: center;

  &:first-child {
    margin-bottom: 0;
  }

  ${up("md")} {
    width: 45%;
    margin: 1rem;
    padding: 3rem 2rem;

    box-shadow: 0 2px 32px rgba(0, 0, 0, 0.15);
  }
`

export const PlanTitle = styled.h1`
  font-size: 1.6rem;
  margin-bottom: 0.7rem;
`

export const PlanDesc = styled.div`
  font-size: 0.9rem;
  margin-bottom: 2rem;
`

export const FeaturesBlock = styled.div`
  display: inline-block;

  p {
    white-space: nowrap;
    text-align: left;
    padding-left: 2.5rem;
    background: url("${check}") no-repeat;
  }
`

export const SubscribeButton = styled(Button)`
  margin: 0 auto;
  background-color: ${palette.main};
  font-size: 1.3rem;
  padding: 0.7rem 2rem;
  margin-bottom: 2rem;
`

export const PriceBlock = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
`

export const Currency = styled.div`
  font-size: 1.2rem;
`

export const Cost = styled.div`
  font-size: 4rem;
  line-height: 4rem;
  color: ${palette.main};
`

export const Suffix = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.9rem;
  margin-left: 1rem;
  text-align: left;
  padding-bottom: 0.5rem;

  div {
    line-height: 1rem;
  }
`

export const AccentFeature = styled.p`
  font-weight: 500;
`

export const SwitcherWrapper = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`
export const SubSwitcher = styled.div`
  font-size: 0.8rem;
  color: ${palette.gray};
  margin-top: 0.3rem;
`

export const FixWidth = styled.div`
  max-width: 60rem;
  margin: 0 auto;
`

export const SubTitle = styled.div`
  text-align: center;
  font-size: 1rem;
  color: ${palette.gray};
  margin-bottom: 2rem;
`

export const Items = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 2rem;

  ${ItemWrapper} {
    &:nth-child(2) {
      background-color: ${palette.main};
      color: ${palette.white};

      ${Cost} {
        color: ${palette.white};
      }

      ${SubscribeButton} {
        background-color: ${palette.white};
        color: ${palette.main};
      }

      ${FeaturesBlock} {
        p {
          background: url("${checkWhite}") no-repeat;
        }
      }
    }
  }

  ${up("md")} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Item = ({ plan, period }) => {
  return (
    <ItemWrapper>
      <PlanTitle>{plan.name}</PlanTitle>
      <PlanDesc>{plan.desc}</PlanDesc>
      <PriceBlock>
        <Currency>$</Currency>
        <Cost>
          {period === periodEnum.Monthly ? plan.cost : plan.costYearly}
        </Cost>
        {plan.cost > 0 && (
          <Suffix>
            <div>per user</div>
            <div>per month</div>
          </Suffix>
        )}
      </PriceBlock>
      <SubscribeButton>{plan.buttonText}</SubscribeButton>

      <FeaturesBlock>
        {plan.cost === 0 && <p>{plan.users}</p>}
        <AccentFeature>
          {plan.cost > 0 ? "Unlimited" : "1000"} translated keys
        </AccentFeature>
        <p>Unlimited languages</p>
        <p>Unlimited projects</p>
        <p>Localization hosting</p>
        <p>CLI tool</p>
      </FeaturesBlock>
    </ItemWrapper>
  )
}

export const Cancel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${palette.gray};

  img {
    width: 3rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  ${up("md")} {
    flex-direction: row;
    text-align: left;

    img {
      margin-bottom: 0;
    }
  }
`

const plans = [
  {
    name: "Free",
    desc: "For individual developers",
    cost: 0,
    costYearly: 0,
    users: "Only 1 user",
    buttonText: "Get started",
  },
  {
    name: "Team",
    desc: "For small projects and startups",
    cost: 9,
    costYearly: 90,
    users: "1",
    buttonText: "Subscribe",
  },
]

export const Prices = () => {
  const [period, setPeriod] = useState(periodEnum.Monthly)

  return (
    <Wrapper>
      <Container>
        <FixWidth>
          <BlockTitle>Simple plans for everyone</BlockTitle>

          <SubTitle>
            One plan - $9 per month per user. <br />
            {/* For small project and startup */}
            {/* FREE forever, but only 1 user included and limit for 500 translation */}
            {/* keys. <br /> */}
            No credit card required.
          </SubTitle>

          <SwitcherWrapper>
            <Switcher period={period} setPeriod={setPeriod} />
            <SubSwitcher>Save 2 months!</SubSwitcher>
          </SwitcherWrapper>

          {/* <div>Price in USD</div> */}

          <Items>
            {plans.map((plan) => (
              <Item plan={plan} period={period} />
            ))}
          </Items>

          <Cancel>
            <img src={card} alt="" />
            All our subscriptions may be cancelled at any time, even annual
            subscriptions (pro-rata reimbursement)
          </Cancel>
        </FixWidth>
      </Container>
    </Wrapper>
  )
}
