import { up } from "styled-breakpoints"
import styled from "styled-components"

import { BlockSubTitle } from "../../uikit/BlockSubTitle"
import { Container } from "../../uikit/Container"
import { BlockTitle } from "../../uikit/BlockTitle"
import { palette } from "../../theme/pallette"

import group from "./imgs/group.svg"
import cloudComputing from "./imgs/cloud-computing.svg"
import maintenance from "./imgs/maintenance.svg"
import translation from "./imgs/translation.svg"

const Wrapper = styled.div`
  padding: 3rem 0;
`

const Image = styled.img`
  display: block;
  width: 6rem;
  margin: 0 auto;
  margin-bottom: 2rem;
`

export const Items = styled.div`
  display: flex;
  flex-direction: column;

  ${up("md")} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const ItemWrapper = styled.div`
  max-width: 350px;
  margin: 0 auto;
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${up("md")} {
    width: 50%;
  }

  ${up("lg")} {
    width: 25%;
    margin-bottom: 0;
  }
`

const ItemTitle = styled.div`
  color: ${palette.white};
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 1rem;
`

const ItemContent = styled.div`
  color: ${palette.white};
  text-align: center;
  font-size: 1rem;
  padding: 0 1rem;
`

export const Benefits = () => {
  return (
    <Container
      bgColor={palette.main}
      bgBlockColor={palette.main}
      color={palette.white}
    >
      <Wrapper>
        <BlockTitle>Benefits</BlockTitle>
        <BlockSubTitle>
          Everyday we work hard to make life of our clients better and happier
        </BlockSubTitle>
        <Items>
          <ItemWrapper>
            <Image src={translation} alt="Localization hosting" />
            <ItemTitle>Localization hosting</ItemTitle>
            <ItemContent>
              Translate and develop at the same time. Store translations on CDN
              servers around the world.
            </ItemContent>
          </ItemWrapper>
          <ItemWrapper>
            <Image src={group} alt="Team collaboration" />
            <ItemTitle>Team collaboration</ItemTitle>
            <ItemContent>
              The shared workspace makes the localization process faster and
              more convenient.
            </ItemContent>
          </ItemWrapper>
          <ItemWrapper>
            <Image src={cloudComputing} alt="Workflow integration" />
            <ItemTitle>Workflow integration</ItemTitle>
            <ItemContent>
              Smoothly integrate the localization process into your workflows.
            </ItemContent>
          </ItemWrapper>
          <ItemWrapper>
            <Image src={maintenance} alt="Easy onboarding" />
            <ItemTitle>Easy onboarding</ItemTitle>
            <ItemContent>
              No need to waste time on training of new employees. We`ve made
              Localeum as clear as possible.
            </ItemContent>
          </ItemWrapper>
        </Items>
      </Wrapper>
    </Container>
  )
}
