import { Helmet } from "react-helmet"

import { MainLayout } from "../../layouts/MainLayout"
import { getSeoTitle } from "../../seo"
import { palette } from "../../theme/pallette"
import { Container } from "../../uikit/Container"
import { DateUpdated, Header, Wrapper } from "../Privacy"
import { Offer } from "./Offer"

export const ForDevelopers = () => {
  return (
    <>
      <Helmet>
        <title>{getSeoTitle("Localization platform for developers")}</title>
      </Helmet>
      <MainLayout>
        <Offer />
      </MainLayout>
    </>
  )
}
