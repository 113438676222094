import { up } from "styled-breakpoints"
import styled from "styled-components"

import { Container } from "../../uikit/Container"
import { BlockTitle } from "../../uikit/BlockTitle"
import { palette } from "../../theme/pallette"

import webApp from "./imgs/web-app.svg"
import mobileApp from "./imgs/mobile-app.svg"
import game from "./imgs/game.svg"
import onlineStore from "./imgs/online-store.svg"

const Wrapper = styled.div`
  padding: 3rem 0;
`

export const Items = styled.div`
  display: flex;
  flex-direction: column;

  ${up("md")} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const ItemWrapper = styled.div`
  max-width: 350px;
  margin: 0 auto;
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${up("md")} {
    width: 50%;
  }

  ${up("lg")} {
    width: 25%;
    margin-bottom: 0;
  }
`

const Image = styled.img`
  display: block;
  width: 5rem;
  margin: 0 auto;
  margin-bottom: 1rem;
`

const ItemTitle = styled.div`
  color: ${palette.white};
  text-align: center;
  font-size: 1.5rem;
`

export const Title = styled(BlockTitle)`
  max-width: 25rem;
  margin: 0 auto;
  margin-bottom: 3rem;

  ${up("lg")} {
    max-width: 100%;
  }
`

export const WhatCanYou = () => {
  return (
    <Container
      bgColor={palette.main}
      bgBlockColor={palette.main}
      color={palette.white}
    >
      <Wrapper>
        <Title>What can you localize with Localeum?</Title>

        <Items>
          <ItemWrapper>
            <Image src={webApp} alt="Web App" />
            <ItemTitle>Web App</ItemTitle>
          </ItemWrapper>
          <ItemWrapper>
            <Image src={mobileApp} alt="Mobile App" />
            <ItemTitle>Mobile App</ItemTitle>
          </ItemWrapper>
          <ItemWrapper>
            <Image src={game} alt="Game" />
            <ItemTitle>Game</ItemTitle>
          </ItemWrapper>
          <ItemWrapper>
            <Image src={onlineStore} alt="Online Store" />
            <ItemTitle>Online Store</ItemTitle>
          </ItemWrapper>
        </Items>
      </Wrapper>
    </Container>
  )
}
