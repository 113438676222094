import React from "react"
import { HashLink as Link } from "react-router-hash-link"

import styled from "styled-components"

export const StyledLink = styled(Link)`
  margin: 0;
  text-decoration: none;
  color: ${({ color }) => color || "blue"}; //#fff;

  &:hover {
    text-decoration: underline;
  }
`

export const HashLink = React.forwardRef((props, ref) => {
  return <StyledLink {...props} ref={ref} />
})
