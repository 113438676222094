export const palette = {
  main: "#7b68ee",
  white: "#fff",
  lightGray: "#d0d0d0",
  gray: "#717782",
  darkGray: "#525252",
  black: "#000",
  textColor: "#292929",
  titleColor: "#080808",
  success: "green",
}
