import { Helmet } from "react-helmet"
import { Benefits } from "../../blocks/Benefits"
import { Features } from "../../blocks/Features"
import { Offer } from "../../blocks/Offer"
import { WhatCanYou } from "../../blocks/WhatCanYou"

import { MainLayout } from "../../layouts/MainLayout"

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>Localeum - localization platform</title>
        <meta
          name="description"
          content="Localeum helps organize the localization process in your company."
        />
      </Helmet>
      <MainLayout>
        <Offer />
        <Benefits />
        <Features />
        <WhatCanYou />
      </MainLayout>
    </>
  )
}
