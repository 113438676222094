import { useState } from "react"
import { Helmet } from "react-helmet"
import { up } from "styled-breakpoints"
import styled from "styled-components"

import {
  Image,
  ItemContent,
  Items,
  ItemTitle,
  ItemWrapper,
  Right,
} from "../../blocks/Features"
import img1 from "../../blocks/Features/assets/f1.jpeg"
import img2 from "../../blocks/Features/assets/f2.jpeg"
import img3 from "../../blocks/Features/assets/f3.jpeg"
import img4 from "../../blocks/Features/assets/f4.jpeg"
import img5 from "../../blocks/Features/assets/f5.jpeg"
import img6 from "../../blocks/Features/assets/f6.jpeg"

import { MainLayout } from "../../layouts/MainLayout"
import { getSeoTitle } from "../../seo"
import { palette } from "../../theme/pallette"
import { BlockTitle } from "../../uikit/BlockTitle"
import { Container } from "../../uikit/Container"

const Wrapper = styled.div`
  padding: 3rem 0;

  ${up("md")} {
    padding: 5rem 0;
  }
`

export const FixWidth = styled.div`
  max-width: 60rem;
  margin: 0 auto;
`

export const SubTitle = styled.div`
  text-align: center;
  font-size: 1rem;
  color: ${palette.gray};
  margin-bottom: 2rem;
`

export const SwitcherWrapper = styled.div`
  text-align: center;
  margin-bottom: 4rem;
`

const LocFiles = () => (
  <Items>
    <ItemWrapper>
      <Image src={img6} alt="Unlimited languages" />
      <Right>
        <ItemTitle>Step 1: Upload your files</ItemTitle>
        <ItemContent>
          Upload your files using the web interface, CLI tool or API.
        </ItemContent>
      </Right>
    </ItemWrapper>
    <ItemWrapper>
      <Image src={img5} alt="Unlimited users" />
      <Right>
        <ItemTitle>Step 2: Translate</ItemTitle>
        <ItemContent>
          Translate into a new language with the help of your translators or
          automatic translation.
        </ItemContent>
      </Right>
    </ItemWrapper>
    <ItemWrapper>
      <Image src={img3} alt="CLI tool" />
      <Right>
        <ItemTitle>Step 3: Deploy</ItemTitle>
        <ItemContent>
          Upload new translations to your application using CLI tool, files or
          CI/CD process integration.
        </ItemContent>
      </Right>
    </ItemWrapper>
  </Items>
)

const StartLoc = () => (
  <Items>
    <ItemWrapper>
      <Image src={img2} alt="Unlimited users" />
      <Right>
        <ItemTitle>Unlimited users</ItemTitle>
        <ItemContent>
          Stop thinking about a place for a new member of your team.
          Localization managers, developers, translators, designers, testers,
          anyone - there are enough space for everybody. Just add and go!
        </ItemContent>
      </Right>
    </ItemWrapper>
    <ItemWrapper>
      <Image src={img3} alt="CLI tool" />
      <Right>
        <ItemTitle>CLI tool</ItemTitle>
        <ItemContent>
          The CLI tool allows you to quickly integrate your localization process
          into your continuous delivery process. Download translations directly
          from the CLI with a simple command.
        </ItemContent>
      </Right>
    </ItemWrapper>
    <ItemWrapper>
      <Image src={img4} alt="Team collaboration" />
      <Right>
        <ItemTitle>Team collaboration</ItemTitle>
        <ItemContent>
          Invite new members to the team. Provide the level of access the user
          needs.
        </ItemContent>
      </Right>
    </ItemWrapper>
  </Items>
)

export const HowItWorks = () => {
  const [currentSwitcher, setSwitcher] = useState(0)

  return (
    <>
      <Helmet>
        <title>{getSeoTitle("How it works?")}</title>
      </Helmet>
      <MainLayout>
        <Container
          bgColor={palette.white}
          bgBlockColor={palette.white}
          color={palette.black}
        >
          <Wrapper>
            <Container>
              <FixWidth>
                <BlockTitle>How it works?</BlockTitle>

                <SubTitle>3 steps to the perfect localization process</SubTitle>

                {/* <SwitcherWrapper> */}
                {/*  <Switcher */}
                {/*    currentSwitcher={currentSwitcher} */}
                {/*    setSwitcher={setSwitcher} */}
                {/*    labels={[ */}
                {/*      "I have localization files", */}
                {/*      "I'm starting localization", */}
                {/*    ]} */}
                {/*  /> */}
                {/* </SwitcherWrapper> */}

                {currentSwitcher === 0 ? <LocFiles /> : <StartLoc />}
              </FixWidth>
            </Container>
          </Wrapper>
        </Container>
      </MainLayout>
    </>
  )
}
