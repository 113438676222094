import { between, down, only } from "styled-breakpoints"
import styled from "styled-components"

export const ContainerWrapper = styled.section`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  background: ${({ bgColor }) => bgColor};
`

export const Content = styled.div`
  background: ${({ bgBlockColor }) => bgBlockColor};
  color: ${({ color }) => color};

  width: 100%;
  max-width: 1192px;
  margin: 0 64px;

  ${down("sm")} {
    margin: 0 24px;
  }

  ${between("md", "lg")} {
    margin: 0 48px;
  }

  //@media screen and (max-width: 551.98px) {
  //  margin: 0 24px;
  //}
  //
  //@media screen and (min-width: 728px) and (max-width: 903.98px) {
  //  margin: 0 48px;
  //}
`

export const Container = ({
  children,
  bgColor,
  bgBlockColor,
  color,
  wrapper,
  ...rest
}) => {
  const Wrapper = wrapper || ContainerWrapper
  return (
    <Wrapper bgColor={bgColor} {...rest}>
      <Content bgBlockColor={bgBlockColor} color={color}>
        {children}
      </Content>
    </Wrapper>
  )
}
