import React, { memo, useMemo, useState } from "react"
import { uniq } from "../../_core/utils/uniq"

import { commonFiles, fileFormat, files, PlatformEnum } from "./config"

import * as S from "./styled"

export const FileFormats = memo(() => {
  const [platform, setPlatform] = useState(null)

  const filteredFiles = useMemo(() => {
    if (!platform) return files

    return files.filter((f) => f.platform.includes(platform))
  }, [platform])

  const filteredExtensions = useMemo(() => {
    const commonExts = commonFiles.reduce((acc, f) => [...acc, ...f.ext], [])
    const filteredExts = filteredFiles.reduce(
      (acc, f) => [...acc, ...f.format],
      []
    )

    return uniq([...filteredExts, ...commonExts]).map((key) => {
      return fileFormat[key]
    })
  }, [filteredFiles])

  return (
    <S.Wrapper>
      {/* <div>Choose a file to upload translations</div> */}
      <S.ButtonFilterGroup>
        <S.ButtonFilter
          active={platform === null}
          onClick={() => setPlatform(null)}
        >
          All
        </S.ButtonFilter>

        {Object.keys(PlatformEnum).map((key) => (
          <S.ButtonFilter
            key={key}
            active={platform === PlatformEnum[key]}
            onClick={() => setPlatform(PlatformEnum[key])}
          >
            {key}
          </S.ButtonFilter>
        ))}
      </S.ButtonFilterGroup>
      <S.Cards>
        {filteredFiles.map((f) => (
          <S.FileWrapper key={f.name}>
            <S.Card onClick={() => {}}>
              <S.Left>
                <S.Title>{f.name}</S.Title>
                <div>
                  <S.SubTitle>{f.subtitle}</S.SubTitle>
                  <S.Extesions>
                    ({f.ext.map((ext) => `.${ext}`).join(", ")})
                  </S.Extesions>
                </div>
              </S.Left>

              <S.Img src={f.img} alt={f.name} />
            </S.Card>
          </S.FileWrapper>
        ))}
      </S.Cards>
      <br />
      <div>File formats</div>
      <br />
      <S.Cards>
        {filteredExtensions.map((f) => (
          <S.ExtesionWrapper key={f.name}>
            <S.Card>
              <S.ExtTitle>{f.name}</S.ExtTitle>
            </S.Card>
          </S.ExtesionWrapper>
        ))}
      </S.Cards>
    </S.Wrapper>
  )
})
