import { up } from "styled-breakpoints"
import styled from "styled-components"
import { palette } from "../theme/pallette"

export const BlockSubTitle = styled.div`
  color: ${palette.white};
  text-align: center;
  max-width: 22rem;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 300;
  margin: 0 auto;
  margin-bottom: 3rem;

  ${up("lg")} {
    max-width: 40rem;
  }
`
