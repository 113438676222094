import styled from "styled-components"
import { palette } from "../theme/pallette"

export const Button = styled.button`
  display: block;
  text-decoration: none;
  padding: 0.3rem 0.7rem;
  font-size: 0.9rem;
  border: none;
  border-radius: 0.5rem;
  background: ${({ bgColor }) => bgColor || palette.main};
  color: ${({ color }) => color || palette.white};
`
