import { up } from "styled-breakpoints"
import styled from "styled-components"
import { BlockTitle } from "../../uikit/BlockTitle"
import { Container } from "../../uikit/Container"
import { palette } from "../../theme/pallette"

import img1 from "./assets/f1.jpeg"
import img2 from "./assets/f2.jpeg"
import img3 from "./assets/f3.jpeg"
import img4 from "./assets/f4.jpeg"
import img5 from "./assets/f5.jpeg"
import img6 from "./assets/f6.jpeg"

const Wrapper = styled.div`
  padding: 3rem 0;
`

export const ItemWrapper = styled.div`
  max-width: 350px;
  margin: 0 auto;
  margin-bottom: 4rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${up("md")} {
    display: flex;
    max-width: 100%;
    align-items: center;
  }
`

export const Items = styled.div`
  ${ItemWrapper}:nth-child(even) {
    flex-direction: row-reverse;
  }
`

export const Image = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;

  ${up("md")} {
    max-width: 50%;
    margin-bottom: 0;
  }
`

export const ItemTitle = styled.div`
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 1rem;

  ${up("md")} {
    text-align: left;
    padding: 0 1rem;
  }
`

export const ItemContent = styled.div`
  text-align: center;
  font-size: 1rem;
  padding: 0 1rem;

  ${up("md")} {
    text-align: left;
  }
`

export const Right = styled.div`
  ${up("lg")} {
    width: 40%;
    margin: 0 auto;
  }
`

export const Features = () => {
  return (
    <Container
      id="features"
      bgColor={palette.white}
      bgBlockColor={palette.white}
      color={palette.black}
    >
      <Wrapper>
        <BlockTitle>Features</BlockTitle>
        <Items>
          <ItemWrapper>
            <Image src={img1} alt="Unlimited languages" />
            <Right>
              <ItemTitle>Unlimited languages</ItemTitle>
              <ItemContent>
                You don`t have to choose which language you will use in your
                current plan - use any. Add new languages without programming.
                Speak the same language to your clients.
              </ItemContent>
            </Right>
          </ItemWrapper>
          <ItemWrapper>
            <Image src={img2} alt="Unlimited users" />
            <Right>
              <ItemTitle>Unlimited users</ItemTitle>
              <ItemContent>
                Stop thinking about a place for a new member of your team.
                Localization managers, developers, translators, designers,
                testers, anyone - there are enough space for everybody. Just add
                and go!
              </ItemContent>
            </Right>
          </ItemWrapper>
          <ItemWrapper>
            <Image src={img3} alt="CLI tool" />
            <Right>
              <ItemTitle>CLI tool</ItemTitle>
              <ItemContent>
                The CLI tool allows you to quickly integrate your localization
                process into your continuous delivery process. Download
                translations directly from the CLI with a simple command.
              </ItemContent>
            </Right>
          </ItemWrapper>
          <ItemWrapper>
            <Image src={img4} alt="Team collaboration" />
            <Right>
              <ItemTitle>Team collaboration</ItemTitle>
              <ItemContent>
                Invite new members to the team. Provide the level of access the
                user needs.
              </ItemContent>
            </Right>
          </ItemWrapper>
          <ItemWrapper>
            <Image src={img5} alt="Intuitive interface" />
            <Right>
              <ItemTitle>Intuitive interface</ItemTitle>
              <ItemContent>
                Nothing superfluous, only the most necessary functionality. You
                feel like you are making a translation, not driving a spaceship.
              </ItemContent>
            </Right>
          </ItemWrapper>
          <ItemWrapper>
            <Image src={img6} alt="Import and export files" />
            <Right>
              <ItemTitle>Import and export files</ItemTitle>
              <ItemContent>
                In Localeum, you can import and export translations. If you
                already have a file with keys and language translations, this
                will instantly help you to migrate all previous translations.
                You can also save a backup.
              </ItemContent>
            </Right>
          </ItemWrapper>
        </Items>
      </Wrapper>
    </Container>
  )
}
