import { down, up } from "styled-breakpoints"
import styled from "styled-components"
import { palette } from "../../theme/pallette"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const ButtonFilterGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  ${down("sm")} {
    position: sticky;
    top: 3.5rem;
    background-color: #fff;
    width: 100%;
    overflow-y: auto;
    z-index: 100;
    padding: 1rem 0;
    margin-top: 0;

    & > div {
      margin-left: 1rem;
      margin-right: 0;
    }

    &:after {
      display: block;
      content: " ";
      min-width: 1rem;
      min-height: 1rem;
    }
  }
`

export const ButtonFilter = styled.div`
  border-radius: 1rem;
  padding: 0.3rem 1rem;
  margin-right: 1rem;
  background-color: ${({ active }) => (active ? palette.main : palette.white)};
  color: ${({ active }) => (active ? palette.white : palette.main)};
  cursor: pointer;
`

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const FileWrapper = styled.div`
  width: 100%;

  ${up("sm")} {
    width: 50%;
  }

  ${up("lg")} {
    width: 33.33%;
  }
`

export const ExtesionWrapper = styled.div`
  width: 100%;

  ${up("sm")} {
    width: 50%;
  }

  ${up("lg")} {
    width: 25%;
  }
`

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 1rem;
  margin: 1rem;
  border-radius: 0.3rem;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  ${up("sm")} {
  }

  ${up("lg")} {
    padding: 1rem 2rem;
  }

  &:hover {
    transform: scale(1.05);
  }
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
`

export const Title = styled.div`
  font-size: 1.3rem;
  margin-bottom: 0.5rem;

  ${up("sm")} {
    font-size: 1.1rem;
  }
`

export const SubTitle = styled.span`
  font-size: 1rem;
  margin-right: 0.3rem;

  ${up("sm")} {
    font-size: 0.7rem;
  }

  ${up("lg")} {
    font-size: 0.8rem;
  }
`

export const Extesions = styled.span`
  color: ${palette.lightGray};

  ${up("sm")} {
    font-size: 0.7rem;
  }
`

export const Img = styled.img`
  width: 2.3rem;
  height: 2.3rem;
`

export const ExtTitle = styled.div`
  font-size: 1rem;
`
