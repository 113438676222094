import styled from "styled-components"

export const BlockTitle = styled.h3`
  text-align: center;
  font-size: 2rem;
  line-height: 1.23;
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
`
