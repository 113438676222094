import FlutterImg from "./images/flutter.svg"
import ReactImg from "./images/react.svg"
import AngularImg from "./images/angular.svg"
import VueImg from "./images/vue.svg"
import NodeJsImg from "./images/nodejs.svg"
import IosImg from "./images/ios.svg"
import LaravelImg from "./images/laravel.svg"
import UnityImg from "./images/unity.svg"
import AndroidImg from "./images/android.svg"
import IonicImg from "./images/ionic.svg"
import GatsbyImg from "./images/gatsby.svg"
import DjangoImg from "./images/django.svg"
import YiiImg from "./images/yii.svg"
import GoImg from "./images/go.svg"
import QtImg from "./images/qt.svg"
import RubyImg from "./images/ruby.svg"
import SwiftImg from "./images/swift.svg"
import CordovaImg from "./images/cordova.svg"
import ExpoImg from "./images/expo.svg"
import SpringBootImg from "./images/spring-boot.svg"
import MacOSImg from "./images/macos.svg"

export const PlatformEnum = {
  Mobile: "mobile",
  Web: "web",
  Desktop: "desktop",
  Server: "server",
}

export const ExtensionEnum = {
  Arb: "arb",
  Json: "json",
  Js: "js",
  Php: "php",
  Po: "po",
  Pot: "pot",
  Yaml: "yml",
  Ts: "ts",
  Xliff: "xliff",
  Strings: "strings",
  StringsDict: "stringsdict",
  Csv: "csv",
  Xlsx: "xlsx",
  Resx: "resx",
  Ini: "ini",
  Properties: "properties",
}

export const FileFormatEnum = {
  Csv: "csv",
  Xlsx: "xlsx",
  Arb: "arb",
  Php: "php",
  Po: "po",
  Pot: "pot",
  Yaml: "yml",
  Ts: "ts",
  Ini: "ini",
  JsonSimple: "json_simple",
  JsonNested: "json_nested",
  RequiredJs: "required_js",
  Xliff_1_2: "xliff_1_2",
  Xliff_2_0: "xliff_2_0",
  Resx: "resx",
  Strings: "strings",
  Properties: "properties",
}

export const commonFiles = [
  {
    name: "CSV",
    subtitle: "ARB file",
    ext: [ExtensionEnum.Csv],
    format: [FileFormatEnum.Csv],
  },
  {
    name: "XLSX",
    subtitle: "Excel file",
    ext: [ExtensionEnum.Xlsx],
    format: [FileFormatEnum.Xlsx],
  },
  {
    name: "INI",
    subtitle: "Ini file",
    ext: [ExtensionEnum.Ini],
    format: [FileFormatEnum.Ini],
  },
  {
    name: "RESX",
    subtitle: "RESX file",
    ext: [ExtensionEnum.Resx],
    format: [FileFormatEnum.Resx],
  },
]

export const files = [
  {
    name: "Android",
    subtitle: "STRINGS file",
    ext: [ExtensionEnum.Strings],
    platform: [PlatformEnum.Mobile],
    format: [FileFormatEnum.Strings],
    img: AndroidImg,
  },
  {
    name: "iOS",
    subtitle: "STRINGS file",
    ext: [ExtensionEnum.Strings],
    platform: [PlatformEnum.Mobile],
    format: [FileFormatEnum.Strings],
    img: IosImg,
  },
  {
    name: "MacOS",
    subtitle: "STRINGS file",
    ext: [ExtensionEnum.Strings],
    platform: [PlatformEnum.Desktop],
    format: [FileFormatEnum.Strings],
    img: MacOSImg,
  },
  {
    name: "Swift",
    subtitle: "STRINGS file",
    ext: [ExtensionEnum.Strings],
    platform: [PlatformEnum.Mobile],
    format: [FileFormatEnum.Strings],
    img: SwiftImg,
  },
  {
    name: "Angular",
    subtitle: "XLIFF 1.2, XLIFF 2.0 file",
    ext: [ExtensionEnum.Xliff],
    platform: [PlatformEnum.Web],
    format: [FileFormatEnum.Xliff_1_2, FileFormatEnum.Xliff_2_0],
    img: AngularImg,
  },
  {
    name: "Flutter",
    subtitle: "ARB file",
    ext: [ExtensionEnum.Arb],
    platform: [PlatformEnum.Desktop, PlatformEnum.Mobile, PlatformEnum.Web],
    format: [FileFormatEnum.Arb],
    img: FlutterImg,
  },

  {
    name: "React",
    subtitle: "JSON file",
    ext: [ExtensionEnum.Json],
    platform: [PlatformEnum.Web],
    format: [FileFormatEnum.JsonSimple, FileFormatEnum.JsonNested],
    img: ReactImg,
  },
  {
    name: "Vue",
    subtitle: "JSON file",
    ext: [ExtensionEnum.Json],
    platform: [PlatformEnum.Web],
    format: [FileFormatEnum.JsonSimple, FileFormatEnum.JsonNested],
    img: VueImg,
  },
  {
    name: "NodeJs",
    subtitle: "JSON file",
    ext: [ExtensionEnum.Json],
    platform: [PlatformEnum.Server],
    format: [FileFormatEnum.JsonSimple, FileFormatEnum.JsonNested],
    img: NodeJsImg,
  },

  {
    name: "Gatsby",
    subtitle: "JSON file",
    ext: [ExtensionEnum.Json],
    platform: [PlatformEnum.Web],
    format: [FileFormatEnum.JsonSimple, FileFormatEnum.JsonNested],
    img: GatsbyImg,
  },
  {
    name: "Ionic",
    subtitle: "JSON file",
    ext: [ExtensionEnum.Json],
    platform: [PlatformEnum.Mobile],
    format: [FileFormatEnum.JsonSimple, FileFormatEnum.JsonNested],
    img: IonicImg,
  },
  {
    name: "Unity",
    subtitle: "JSON file",
    ext: [ExtensionEnum.Json],
    platform: [PlatformEnum.Desktop],
    format: [FileFormatEnum.JsonSimple, FileFormatEnum.JsonNested],
    img: UnityImg,
  },
  {
    name: "Expo",
    subtitle: "JSON file",
    ext: [ExtensionEnum.Json],
    platform: [PlatformEnum.Mobile],
    format: [FileFormatEnum.JsonSimple, FileFormatEnum.JsonNested],
    img: ExpoImg,
  },
  {
    name: "Cordova",
    subtitle: "JSON file",
    ext: [ExtensionEnum.Json],
    platform: [PlatformEnum.Web],
    format: [FileFormatEnum.JsonSimple, FileFormatEnum.JsonNested],
    img: CordovaImg,
  },
  {
    name: "React Native",
    subtitle: "JSON file",
    ext: [ExtensionEnum.Json],
    platform: [PlatformEnum.Mobile],
    format: [FileFormatEnum.JsonSimple, FileFormatEnum.JsonNested],
    img: ReactImg,
  },
  {
    name: "Laravel",
    subtitle: "PHP file",
    ext: [ExtensionEnum.Php],
    platform: [PlatformEnum.Server, PlatformEnum.Web],
    format: [FileFormatEnum.JsonSimple, FileFormatEnum.JsonNested],
    img: LaravelImg,
  },
  {
    name: "Yii",
    subtitle: "PHP file",
    ext: [ExtensionEnum.Php],
    platform: [PlatformEnum.Server, PlatformEnum.Web],
    format: [FileFormatEnum.JsonSimple, FileFormatEnum.JsonNested],
    img: YiiImg,
  },
  {
    name: "Django",
    subtitle: "PO file",
    ext: [ExtensionEnum.Po],
    platform: [PlatformEnum.Server, PlatformEnum.Web],
    format: [FileFormatEnum.Po, FileFormatEnum.Pot],
    img: DjangoImg,
  },

  {
    name: "Qt",
    subtitle: "TS file",
    ext: [ExtensionEnum.Ts],
    platform: [PlatformEnum.Desktop],
    format: [FileFormatEnum.Ts],
    img: QtImg,
  },
  {
    name: "Go",
    subtitle: "JSON, YAML file",
    ext: [ExtensionEnum.Json, ExtensionEnum.Yaml],
    platform: [PlatformEnum.Server],
    format: [FileFormatEnum.JsonSimple, FileFormatEnum.JsonNested],
    img: GoImg,
  },
  {
    name: "Spring Boot",
    subtitle: "PROPERTIES file",
    ext: [ExtensionEnum.Properties],
    platform: [PlatformEnum.Server, PlatformEnum.Web],
    format: [FileFormatEnum.Properties],
    img: SpringBootImg,
  },
  {
    name: "Ruby on Rails",
    subtitle: "YAML file",
    ext: [ExtensionEnum.Yaml],
    platform: [PlatformEnum.Server, PlatformEnum.Web],
    format: [FileFormatEnum.Yaml],
    img: RubyImg,
  },
]

export const fileFormat = {
  [FileFormatEnum.Arb]: {
    name: "ARB",
    subtitle: "ARB file",
  },
  [FileFormatEnum.Strings]: {
    name: "Strings",
    subtitle: "ARB file",
  },
  [FileFormatEnum.Po]: {
    name: "Po",
    subtitle: "ARB file",
  },
  [FileFormatEnum.Pot]: {
    name: "Pot",
    subtitle: "ARB file",
  },
  [FileFormatEnum.Php]: {
    name: "PHP",
    subtitle: "ARB file",
  },
  [FileFormatEnum.Yaml]: {
    name: "Yaml",
    subtitle: "ARB file",
  },
  [FileFormatEnum.JsonSimple]: {
    name: "JSON",
    subtitle: "ARB file",
  },
  [FileFormatEnum.JsonNested]: {
    name: "JSON Nested",
    subtitle: "ARB file",
  },
  [FileFormatEnum.RequiredJs]: {
    name: "Required JS",
    subtitle: "ARB file",
  },
  [FileFormatEnum.Ts]: {
    name: "TS",
    subtitle: "ARB file",
  },
  [FileFormatEnum.Xliff_1_2]: {
    name: "XLIFF 1.2",
    subtitle: "ARB file",
  },
  [FileFormatEnum.Xliff_2_0]: {
    name: "XLIFF 2.0",
    subtitle: "ARB file",
  },

  [FileFormatEnum.Csv]: {
    name: "CSV",
    subtitle: "ARB file",
  },
  [FileFormatEnum.Xlsx]: {
    name: "XLSX",
    subtitle: "ARB file",
  },
  [FileFormatEnum.Ini]: {
    name: "INI",
    subtitle: "INI file",
  },
  [FileFormatEnum.Resx]: {
    name: "RESX",
    subtitle: "RESX file",
  },
  [FileFormatEnum.Properties]: {
    name: "PROPERTIES",
    subtitle: "PROPERTIES file",
  },
}
