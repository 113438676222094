import { up } from "styled-breakpoints"
import Typewriter from "typewriter-effect"

import { useBreakpoint } from "styled-breakpoints/react-styled"
import styled from "styled-components"
import { paths } from "../../paths"
import { Button } from "../../uikit/Button"
import { Container } from "../../uikit/Container"
import { ExternalLink } from "../../uikit/ExternalLink"
import { Text } from "../../uikit/Text"
import { palette } from "../../theme/pallette"
import offer from "./assets/offer.png"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 3rem;

  ${up("lg")} {
    padding-top: 3rem;
    justify-content: space-evenly;
    flex-direction: row;
  }
`

export const Left = styled.div`
  ${up("lg")} {
    margin-right: 3rem;
  }
`

export const H1 = styled.h1`
  text-align: center;
  max-width: 500px;
  font-size: 1.6rem;
  line-height: 1.4;
  color: ${palette.titleColor};

  span {
    color: ${palette.main};
  }

  ${up("md")} {
    font-size: 2rem;
  }

  ${up("lg")} {
    text-align: left;
  }
`
export const SubOffer = styled(Text)`
  margin-bottom: 2rem;
  font-size: 1rem;
  max-width: 470px;
  text-align: center;

  ${up("lg")} {
    text-align: left;
  }
`

export const Image = styled.img`
  width: 100%;
  max-width: 28rem;
  margin-bottom: 2rem;

  ${up("lg")} {
    width: 45%;
    max-width: 40rem;
  }
`
export const WrapperButton = styled.div`
  text-align: center;
  margin-bottom: 2rem;

  ${up("lg")} {
    margin-bottom: 0;
  }
`

export const CtaButton = styled.a`
  display: inline-block;
  font-size: 1.1rem;
  padding: 0.7rem 2rem;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  background-color: ${palette.main};
  color: ${palette.white};
  border-radius: 1rem;
  cursor: pointer;
  text-decoration: none;

  ${up("lg")} {
    font-size: 1.3rem;
    padding: 0.7rem 2rem;
  }
`

export const SubButton = styled.div`
  font-size: 0.8rem;
  opacity: 0.5;
`

const ButtonBlock = () => {
  return (
    <WrapperButton>
      <CtaButton href={paths.signup()}>Get your free account</CtaButton>
      <SubButton>No credit card required!</SubButton>
    </WrapperButton>
  )
}

export const Offer = () => {
  const upLg = useBreakpoint(up("lg"))

  return (
    <Container
      bgColor={palette.white}
      bgBlockColor={palette.white}
      color={palette.black}
    >
      <Wrapper>
        <Left>
          <H1>
            Simple localization platform{" "}
            <Typewriter
              options={{
                strings: [
                  "for efficient teams",
                  "for developers",
                  "for startups",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </H1>
          <SubOffer>
            Make your localization process flawless. Easy integration into your
            current CI/CD process. Effective team collaboration. Fast deployment
            in a production environment.
          </SubOffer>

          {upLg && <ButtonBlock />}
        </Left>

        {!upLg && <ButtonBlock />}
        <Image src={offer} alt="" />
      </Wrapper>
    </Container>
  )
}
