import { Helmet } from "react-helmet"

import { MainLayout } from "../../layouts/MainLayout"
import { getSeoTitle } from "../../seo"
import { palette } from "../../theme/pallette"
import { Container } from "../../uikit/Container"
import { DateUpdated, Header, Wrapper } from "../Privacy"

export const ForStartups = () => {
  return (
    <>
      <Helmet>
        <title>{getSeoTitle("Integrations")}</title>
      </Helmet>
      <MainLayout>
        <Container
          bgColor={palette.white}
          bgBlockColor={palette.white}
          color={palette.black}
        >
          <Wrapper>
            <Header>
              <h1>Integrations</h1>
              <DateUpdated>Updated at 2021-03-01</DateUpdated>
            </Header>
          </Wrapper>
        </Container>
      </MainLayout>
    </>
  )
}
