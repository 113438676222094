import styled from "styled-components"
import { palette } from "../../theme/pallette"
import { periodEnum } from "./enums"

export const Wrapper = styled.div`
  display: inline-block;
  background-color: ${palette.white};
  border-radius: 0.5rem;
`

export const SwitchButton = styled.div`
  display: inline-block;
  background-color: ${({ active }) => (active ? palette.main : "transparent")};
  color: ${({ active }) => (active ? palette.white : palette.black)};
  padding: 0.5rem 1.5rem;
  border-radius: ${({ active }) => (active ? "0.5rem" : "none")};
  cursor: pointer;
`

export const Switcher = ({ period, setPeriod }) => {
  return (
    <Wrapper>
      <SwitchButton
        active={period === periodEnum.Monthly}
        onClick={() => setPeriod(periodEnum.Monthly)}
      >
        Monthly
      </SwitchButton>
      <SwitchButton
        active={period === periodEnum.Yearly}
        onClick={() => setPeriod(periodEnum.Yearly)}
      >
        Yearly
      </SwitchButton>
    </Wrapper>
  )
}
