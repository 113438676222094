import { up } from "styled-breakpoints"
import styled from "styled-components"

import { useScroll } from "../../_core/hooks/useScroll"

import { paths } from "../../paths"
import { palette } from "../../theme/pallette"
import { Container, ContainerWrapper } from "../../uikit/Container"
import { ExternalLink } from "../../uikit/ExternalLink"
import { HashLink } from "../../uikit/HashLink"
import { Link } from "../../uikit/Link"

import logo from "./assets/white-logo.svg"
import twitter from "./assets/twitter-icon.svg"
import github from "./assets/github-icon.svg"
import facebook from "./assets/facebook-icon.svg"
import reddit from "./assets/reddit-icon.svg"

export const WrapperContainer = styled(ContainerWrapper)`
  flex-grow: 0;
`

export const Column = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;

  h5 {
    font-size: 1.125rem;
    font-weight: 500;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  ${up("md")} {
    width: 50%;
    text-align: left;
  }

  ${up("lg")} {
    width: 25%;
    margin-bottom: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 4rem;

  ${up("md")} {
    flex-direction: row;
    flex-wrap: wrap;

    ${Column}:nth-child(1) {
      order: 1;
    }

    ${Column}:nth-child(4) {
      order: 2;
    }
  }

  ${up("lg")} {
    flex-direction: row;
    flex-wrap: wrap;

    ${Column}:nth-child(1) {
      width: 31%;
    }

    ${Column} {
      width: 23%;
      order: 1;
    }
  }
`

export const Logo = styled.img`
  width: 45%;
  max-width: 12rem;
`

export const Text = styled.p`
  font-size: 0.875rem;
  color: ${palette.gray};
  margin: 0 auto;
  max-width: 22rem;

  ${up("lg")} {
    padding-right: 1rem;
    max-width: 18rem;
    margin: 0;
  }
`

export const MenuItem = styled.div`
  a {
    font-size: 0.875rem;
    color: ${palette.lightGray};

    &:hover {
      text-decoration: none;
      color: ${palette.gray};
    }
  }
`

export const Copyright = styled.div`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 0.875rem;
  color: ${palette.gray};

  ${up("lg")} {
    text-align: left;
  }
`

export const SocIcon = styled.a`
  img {
    opacity: 0.6;
    width: 1.5rem;
    height: 1.5rem;

    &:hover {
      opacity: 1;
    }
  }
`

export const FollowBlock = styled.div`
  //margin-top: 2rem;

  ${SocIcon} {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const Footer = () => {
  return (
    <Container
      bgColor={palette.black}
      bgBlockColor={palette.black}
      color={palette.white}
      wrapper={WrapperContainer}
    >
      <Wrapper>
        <Column>
          <Logo src={logo} alt="Localeum" />
          <Text>
            Localeum helps organize the localization process in your company.
          </Text>

          {/* <Text> */}
          {/*  Made with{" "} */}
          {/*  <span role="img" aria-label="love"> */}
          {/*    ❤️ */}
          {/*  </span>{" "} */}
          {/*  in Europe. */}
          {/* </Text> */}
        </Column>

        <Column>
          <h5>Product</h5>
          <MenuItem>
            <HashLink to={paths.features()}>Features</HashLink>
          </MenuItem>
          <MenuItem>
            <Link to={paths.pricing()}>Pricing</Link>
          </MenuItem>
          <MenuItem>
            <ExternalLink to={paths.docs()}>Documentation</ExternalLink>
          </MenuItem>
          <MenuItem>
            <ExternalLink to={paths.cliTool()}>CLI tool</ExternalLink>
          </MenuItem>
          <MenuItem>
            <ExternalLink to={paths.vscExtension()}>
              VS Code extension
            </ExternalLink>
          </MenuItem>
        </Column>

        <Column>
          <h5>Company</h5>
          <MenuItem>
            <Link to={paths.privacy()}>Privacy policy</Link>
          </MenuItem>
          {/* <MenuItem> */}
          {/*  <ExternalLink to={paths.privacy()}>Terms</ExternalLink> */}
          {/* </MenuItem> */}
          <MenuItem>
            <ExternalLink to={paths.statusPage()}>Status</ExternalLink>
          </MenuItem>
          {/* <MenuItem> */}
          {/*  <Link to={paths.privacy()}>Media Kit</Link> */}
          {/* </MenuItem> */}
        </Column>

        <Column>
          {/* <h5>Customers</h5> */}
          {/* <MenuItem> */}
          {/*  <Link to={paths.privacy()}>Contact us</Link> */}
          {/* </MenuItem> */}

          <FollowBlock>
            <h5>Follow</h5>
            <SocIcon href={paths.twitter()} target="_blank">
              <img src={twitter} alt="Twitter" />
            </SocIcon>
            <SocIcon href={paths.github()} target="_blank">
              <img src={github} alt="GitHub" />
            </SocIcon>
            <SocIcon href={paths.facebook()} target="_blank">
              <img src={facebook} alt="Facebook" />
            </SocIcon>
            <SocIcon href={paths.reddit()} target="_blank">
              <img src={reddit} alt="Reddit" />
            </SocIcon>
          </FollowBlock>
        </Column>
      </Wrapper>
      <Copyright>© 2021 Localeum.</Copyright>
    </Container>
  )
}
