import styled from "styled-components"

import { Footer } from "../../blocks/Footer"
import { Header } from "../../blocks/Header"

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const MainLayout = ({ children }) => {
  return (
    <>
      <Header />
      <Content>{children}</Content>
      <Footer />
    </>
  )
}
