import { Helmet } from "react-helmet"
import { up } from "styled-breakpoints"
import styled from "styled-components"
import { FileFormats } from "../../blocks/FileFormats"

import { MainLayout } from "../../layouts/MainLayout"
import { getSeoTitle } from "../../seo"
import { palette } from "../../theme/pallette"
import { BlockTitle } from "../../uikit/BlockTitle"
import { Container } from "../../uikit/Container"

const Wrapper = styled.div`
  padding: 3rem 0;

  ${up("md")} {
    padding: 5rem 0;
  }
`

export const FixWidth = styled.div`
  max-width: 60rem;
  margin: 0 auto;
`

export const Integrations = () => {
  return (
    <>
      <Helmet>
        <title>{getSeoTitle("Integrations")}</title>
      </Helmet>
      <MainLayout>
        <Container
          bgColor={palette.white}
          bgBlockColor={palette.white}
          color={palette.black}
        >
          <Wrapper>
            <Container>
              <FixWidth>
                <BlockTitle>Integrations</BlockTitle>
              </FixWidth>

              <FileFormats />
            </Container>
          </Wrapper>
        </Container>
      </MainLayout>
    </>
  )
}
