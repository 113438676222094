export const paths = {
  home: () => "/",
  terms: () => "/terms",
  privacy: () => "/privacy",
  pricing: () => "/pricing",
  features: () => "/#features",
  integrations: () => "/integrations",
  howItWorks: () => "/how-it-works",
  forDevelopers: () => "/for-developers",
  forTranslators: () => "/for-translators",
  forManagers: () => "/for-managers",
  forStartups: () => "/for-startups",

  docs: () => "https://docs.localeum.com",
  login: () => "https://app.localeum.com/login",
  signup: () => "https://app.localeum.com/signup",

  cliTool: () => "https://github.com/localeum/localeum-cli",
  vscExtension: () =>
    "https://marketplace.visualstudio.com/items?itemName=Localeum.localization-helper",
  statusPage: () => "https://stats.uptimerobot.com/50vzzF0D61",

  twitter: () => "https://twitter.com/localeum",
  github: () => "https://github.com/localeum",
  facebook: () => "https://www.facebook.com/localeum",
  reddit: () => "https://www.reddit.com/r/localeum/",
}
