import React from "react"
import styled from "styled-components"

export const StyledLink = styled.a`
  margin: 0;
  text-decoration: none;
  color: ${({ color }) => color || "blue"}; //#fff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const ExternalLink = ({ children, to }) => {
  return <StyledLink href={to}>{children}</StyledLink>
}
