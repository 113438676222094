import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { ScrollToTop } from "./_core/components/ScrollToTop"
import { ForDevelopers } from "./pages/ForDevelopers"
import { ForManagers } from "./pages/ForManagers"
import { ForStartups } from "./pages/ForStartups"
import { ForTranslators } from "./pages/ForTranslators"

import { Home } from "./pages/Home"
import { HowItWorks } from "./pages/HowItWorks"
import { Integrations } from "./pages/Integrations"
import { NotFound } from "./pages/NotFound"
import { Pricing } from "./pages/Pricing"
import { Privacy } from "./pages/Privacy"
import { paths } from "./paths"

export const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path={paths.home()}>
            <Home />
          </Route>
          <Route path={paths.privacy()}>
            <Privacy />
          </Route>
          <Route path={paths.howItWorks()}>
            <HowItWorks />
          </Route>
          <Route path={paths.integrations()}>
            <Integrations />
          </Route>
          <Route path={paths.forDevelopers()}>
            <ForDevelopers />
          </Route>
          <Route path={paths.forManagers()}>
            <ForManagers />
          </Route>
          <Route path={paths.forTranslators()}>
            <ForTranslators />
          </Route>
          <Route path={paths.forStartups()}>
            <ForStartups />
          </Route>
          <Route path={paths.pricing()}>
            <Pricing />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </>
  )
}
