import { Helmet } from "react-helmet"
import styled from "styled-components"
import { MainLayout } from "../../layouts/MainLayout"
import { getSeoTitle } from "../../seo"
import { palette } from "../../theme/pallette"
import { Container } from "../../uikit/Container"
import { ExternalLink } from "../../uikit/ExternalLink"

export const Wrapper = styled.div``

export const Header = styled.div`
  margin-bottom: 3rem;

  h1 {
    margin-bottom: 0.5rem;
  }
`

export const DateUpdated = styled.div`
  font-size: 0.8rem;
  color: ${palette.gray};
`

export const Block = styled.div`
  margin-bottom: 4rem;

  p {
    font-size: 1rem;
  }
`

export const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>{getSeoTitle("Privacy Policy")}</title>
        <meta
          name="description"
          content="The latest version of the Localeum's Privacy Policy."
        />
      </Helmet>
      <MainLayout>
        <Container
          bgColor={palette.white}
          bgBlockColor={palette.white}
          color={palette.black}
        >
          <Wrapper>
            <Header>
              <h1>Privacy Policy</h1>
              <DateUpdated>Updated at 2021-03-01</DateUpdated>
            </Header>

            <Block>
              <p>
                Localeum (“we,” “our,” or “us”) is committed to protecting your
                privacy. This Privacy Policy explains how your personal
                information is collected, used, and disclosed by Localeum.
              </p>
              <p>
                This Privacy Policy applies to our website, and its associated
                subdomains (collectively, our “Service”) alongside our
                application, Localeum. By accessing or using our Service, you
                signify that you have read, understood, and agree to our
                collection, storage, use, and disclosure of your personal
                information as described in this Privacy Policy and our Terms of
                Service.
              </p>
            </Block>

            <Block>
              <h3>Definitions and key terms</h3>
              <p>
                To help explain things as clearly as possible in this Privacy
                Policy, every time any of these terms are referenced, are
                strictly defined as:
              </p>
              <ul>
                <li>
                  Cookie: small amount of data generated by a website and saved
                  by your web browser. It is used to identify your browser,
                  provide analytics, remember information about you such as your
                  language preference or login information.
                </li>
                <li>
                  Company: when this policy mentions “Company,” “we,” “us,” or
                  “our,” it refers to Localeum, that is responsible for your
                  information under this Privacy Policy.
                </li>
                {/* <li> */}
                {/*  Country: where Localeum or the owners/founders of Localeum are */}
                {/*  based, in this case is Russia */}
                {/* </li> */}
                <li>
                  Customer: refers to the company, organization or person that
                  signs up to use the Localeum Service to manage the
                  relationships with your consumers or service users.
                </li>
                <li>
                  Device: any internet connected device such as a phone, tablet,
                  computer or any other device that can be used to visit
                  Localeum and use the services.
                </li>
                <li>
                  IP address: Every device connected to the Internet is assigned
                  a number known as an Internet protocol (IP) address. These
                  numbers are usually assigned in geographic blocks. An IP
                  address can often be used to identify the location from which
                  a device is connecting to the Internet.
                </li>
                <li>
                  Personnel: refers to those individuals who are employed by
                  Localeum or are under contract to perform a service on behalf
                  of one of the parties.
                </li>
                <li>
                  Personal Data: any information that directly, indirectly, or
                  in connection with other information — including a personal
                  identification number — allows for the identification or
                  identifiability of a natural person.
                </li>
                <li>
                  Service: refers to the service provided by Localeum as
                  described in the relative terms (if available) and on this
                  platform.
                </li>
                <li>
                  Third-party service: refers to advertisers, contest sponsors,
                  promotional and marketing partners, and others who provide our
                  content or whose products or services we think may interest
                  you.
                </li>
                <li>
                  Website: Localeum’s site, which can be accessed via this URL:
                  https://localeum.com
                </li>
                <li>
                  You: a person or entity that is registered with Localeum to
                  use the Services.
                </li>
              </ul>
            </Block>

            <Block>
              <h3>Information automatically collected</h3>
              <p>
                There is some information like your Internet Protocol (IP)
                address and/or browser and device characteristics — is collected
                automatically when you visit our platform. This information may
                be used to connect your computer to the Internet. Other
                information collected automatically could be a login, e-mail
                address, password, computer and connection information such as
                browser plug-in types and versions and time zone setting,
                operating systems and platforms, purchase history, (we sometimes
                aggregate with similar information from other Users), the full
                Uniform Resource Locator (URL) clickstream to, through and from
                our Website that may include date and time; cookie number; parts
                of the site you viewed or searched for; and the phone number you
                used to call our Customer Services. We may also use browser data
                such as cookies, Flash cookies (also known as Flash Local Shared
                Objects) or similar data on certain parts of our Website for
                fraud prevention and other purposes. During your visits, we may
                use software tools such as JavaScript to measure and collect
                session information including page response times, download
                errors, length of visits to certain pages, page interaction
                information (such as scrolling, clicks, and mouse-overs), and
                methods used to browse away from the page. We may also collect
                technical information to help us identify your device for fraud
                prevention and diagnostic purposes.
              </p>
              <p>
                We automatically collect certain information when you visit, use
                or navigate the platform. This information does not reveal your
                specific identity (like your name or contact information) but
                may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about who and when you use our and other
                technical information. This information is primarily needed to
                maintain the security and operation of our platform, and for our
                internal analytics and reporting purposes.
              </p>
            </Block>

            <Block>
              <h3>Sale of Business</h3>
              <p>
                We reserve the right to transfer information to a third party in
                the event of a sale, merger or other transfer of all or
                substantially all of the assets of Localeum or any of its
                Corporate Affiliates (as defined herein), or that portion of
                Localeum or any of its Corporate Affiliates to which the Service
                relates, or in the event that we discontinue our business or
                file a petition or have filed against us a petition in
                bankruptcy, reorganization or similar proceeding, provided that
                the third party agrees to adhere to the terms of this Privacy
                Policy.
              </p>
            </Block>

            <Block>
              <h3>Affiliates</h3>
              <p>
                We may disclose information (including personal information)
                about you to our Corporate Affiliates. For purposes of this
                Privacy Policy, “Corporate Affiliate“ means any person or entity
                which directly or indirectly controls, is controlled by or is
                under common control with Localeum, whether by ownership or
                otherwise. Any information relating to you that we provide to
                our Corporate Affiliates will be treated by those Corporate
                Affiliates in accordance with the terms of this Privacy Policy.
              </p>
            </Block>

            <Block>
              <h3>Governing Law</h3>
              <p>
                This Privacy Policy is governed by the laws of Russia without
                regard to its conflict of laws provision. You consent to the
                exclusive jurisdiction of the courts in connection with any
                action or dispute arising between the parties under or in
                connection with this Privacy Policy except for those individuals
                who may have rights to make claims under Privacy Shield, or the
                Swiss-US framework.
              </p>
              <p>
                The laws of Russia, excluding its conflicts of law rules, shall
                govern this Agreement and your use of the website. Your use of
                the website may also be subject to other local, state, national,
                or international laws.
              </p>
              <p>
                By using Localeum or contacting us directly, you signify your
                acceptance of this Privacy Policy. If you do not agree to this
                Privacy Policy, you should not engage with our website, or use
                our services. Continued use of the website, direct engagement
                with us, or following the posting of changes to this Privacy
                Policy that do not significantly affect the use or disclosure of
                your personal information will mean that you accept those
                changes.
              </p>
            </Block>

            <Block>
              <h3>Your Consent</h3>
              <p>
                We`ve updated our Privacy Policy to provide you with complete
                transparency into what is being set when you visit our site and
                how it`s being used. By using our Localeum, registering an
                account, or making a purchase, you hereby consent to our Privacy
                Policy and agree to its terms.
              </p>
            </Block>

            <Block>
              <h3>Links to Other Websites</h3>
              <p>
                This Privacy Policy applies only to the Services. The Services
                may contain links to other websites not operated or controlled
                by Localeum. We are not responsible for the content, accuracy or
                opinions expressed in such websites, and such websites are not
                investigated, monitored or checked for accuracy or completeness
                by us. Please remember that when you use a link to go from the
                Services to another website, our Privacy Policy is no longer in
                effect. Your browsing and interaction on any other website,
                including those that have a link on our platform, is subject to
                that website’s own rules and policies. Such third parties may
                use their own cookies or other methods to collect information
                about you.
              </p>
            </Block>

            <Block>
              <h3>Cookies</h3>
              <p>
                Localeum uses “Cookies“ to identify the areas of our website
                that you have visited. A Cookie is a small piece of data stored
                on your computer or mobile device by your web browser. We use
                Cookies to enhance the performance and functionality of our
                website but are non-essential to their use. However, without
                these cookies, certain functionality like videos may become
                unavailable or you would be required to enter your login details
                every time you visit the website as we would not be able to
                remember that you had logged in previously. Most web browsers
                can be set to disable the use of Cookies. However, if you
                disable Cookies, you may not be able to access functionality on
                our website correctly or at all. We never place Personally
                Identifiable Information in Cookies.
              </p>
            </Block>

            <Block>
              <h3>Blocking and disabling cookies and similar technologies</h3>
              <p>
                Wherever you`re located you may also set your browser to block
                cookies and similar technologies, but this action may block our
                essential cookies and prevent our website from functioning
                properly, and you may not be able to fully utilize all of its
                features and services. You should also be aware that you may
                also lose some saved information (e.g. saved login details, site
                preferences) if you block cookies on your browser. Different
                browsers make different controls available to you. Disabling a
                cookie or category of cookie does not delete the cookie from
                your browser, you will need to do this yourself from within your
                browser, you should visit your browser`s help menu for more
                information.
              </p>
            </Block>

            <Block>
              <h3>Remarketing Services</h3>
              <p>
                We use remarketing services. What Is Remarketing? In digital
                marketing, remarketing (or retargeting) is the practice of
                serving ads across the internet to people who have already
                visited your website. It allows your company to seem like
                they`re “following” people around the internet by serving ads on
                the websites and platforms they use most.
              </p>
            </Block>

            <Block>
              <h3>Payment Details</h3>
              <p>
                In respect to any credit card or other payment processing
                details you have provided us, we commit that this confidential
                information will be stored in the most secure manner possible.
              </p>
            </Block>

            <Block>
              <h3>Kids` Privacy</h3>
              <p>
                We collect information from kids under the age of 13 just to
                better our services. If You are a parent or guardian and You are
                aware that Your child has provided Us with Personal Data without
                your permission, please contact Us. If We become aware that We
                have collected Personal Data from anyone under the age of 13
                without verification of parental consent, We take steps to
                remove that information from Our servers.
              </p>
            </Block>

            <Block>
              <h3>Changes To Our Privacy Policy</h3>
              <p>
                We may change our Service and policies, and we may need to make
                changes to this Privacy Policy so that they accurately reflect
                our Service and policies. Unless otherwise required by law, we
                will notify you (for example, through our Service) before we
                make changes to this Privacy Policy and give you an opportunity
                to review them before they go into effect. Then, if you continue
                to use the Service, you will be bound by the updated Privacy
                Policy. If you do not want to agree to this or any updated
                Privacy Policy, you can delete your account.
              </p>
            </Block>

            <Block>
              <h3>Third-Party Services</h3>
              <p>
                We may display, include or make available third-party content
                (including data, information, applications and other products
                services) or provide links to third-party websites or services
                (“Third-Party Services“).
              </p>
              <p>
                You acknowledge and agree that Localeum shall not be responsible
                for any Third-Party Services, including their accuracy,
                completeness, timeliness, validity, copyright compliance,
                legality, decency, quality or any other aspect thereof. Localeum
                does not assume and shall not have any liability or
                responsibility to you or any other person or entity for any
                Third-Party Services.
              </p>
              <p>
                Third-Party Services and links thereto are provided solely as a
                convenience to you and you access and use them entirely at your
                own risk and subject to such third parties terms and conditions.
              </p>
            </Block>

            <Block>
              <h3>Tracking Technologies</h3>
              <ul>
                <li>
                  Local Storage
                  <p>
                    Local Storage sometimes known as DOM storage, provides web
                    apps with methods and protocols for storing client-side
                    data. Web storage supports persistent data storage, similar
                    to cookies but with a greatly enhanced capacity and no
                    information stored in the HTTP request header.
                  </p>
                </li>
              </ul>
            </Block>

            <Block>
              <h3>Contact Us</h3>
              <p>Don`t hesitate to contact us if you have any questions.</p>
              <ul>
                <li>
                  Via Email:{" "}
                  <ExternalLink to="mailto:hello@localeum.com">
                    hello@localeum.com
                  </ExternalLink>
                </li>
              </ul>
            </Block>
          </Wrapper>
        </Container>
      </MainLayout>
    </>
  )
}
