import { Helmet } from "react-helmet"

import { Prices } from "../../blocks/Prices"
import { MainLayout } from "../../layouts/MainLayout"
import { getSeoTitle } from "../../seo"

export const Pricing = () => {
  return (
    <>
      <Helmet>
        <title>{getSeoTitle("Plans & Pricing")}</title>
        <meta
          name="description"
          content="Subscription plans. Free for 1 user and up to 1000 strings."
        />
      </Helmet>
      <MainLayout>
        <Prices />
      </MainLayout>
    </>
  )
}
