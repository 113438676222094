import { down, up } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import styled from "styled-components"

import { useOpenable } from "../../_core/hooks/useOpenable"
import { useScroll } from "../../_core/hooks/useScroll"
import { paths } from "../../paths"

import { Container, ContainerWrapper } from "../../uikit/Container"
import { palette } from "../../theme/pallette"
import { HashLink } from "../../uikit/HashLink"
import { Link } from "../../uikit/Link"
import menu from "./assets/menu.svg"
import logo from "./assets/white-logo.svg"

export const WrapperContainer = styled(ContainerWrapper)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
`

export const MenuIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1rem;
`

export const LogoLink = styled(Link)`
  height: 2.5rem;
`

export const Logo = styled.img`
  height: 2.5rem;
`

export const DocsLink = styled.a`
  text-decoration: none;
  color: ${palette.white};
`

export const SignupLink = styled.a`
  display: block;
  margin-left: 1rem;
  text-decoration: none;
  font-weight: 500;
  background: ${palette.main};
  color: ${palette.white};
  padding: 0 1.7rem;
  font-size: 0.9rem;
  border: none;
  border-radius: 0.5rem;

  ${down("sm")} {
    padding: 0 0.7rem;
  }

  ${up("lg")} {
    padding: 0.3rem 1.7rem;
  }
`

export const LoginLink = styled.a`
  display: block;
  margin-left: 2rem;
  text-decoration: none;
  font-weight: 500;
  color: ${palette.white};
  padding: 0.3rem 1.7rem;
  font-size: 0.9rem;
  border: 1px solid ${palette.main};
  border-radius: 0.5rem;
`

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Menu = styled.div`
  display: flex;

  a {
    display: block;
    line-height: 2.5rem;
    padding: 0 0.7rem;

    &:hover {
      text-decoration: none;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

export const FixHeight = styled.div`
  height: 3.5rem;
  flex-shrink: 0;
`

export const SubMenu = styled.div`
  padding: 1rem 0;
  //text-align: center;
  padding-left: 0.5rem;

  a {
    display: block;
    padding: 1rem 0;
    font-size: 1.2rem;

    &:hover {
      text-decoration: none;
    }
  }
`

export const Header = () => {
  const { open, onOpen, onClose } = useOpenable()

  const upLg = useBreakpoint(up("lg"))

  return (
    <>
      <Container
        bgColor={palette.black}
        bgBlockColor={palette.black}
        color={palette.white}
        wrapper={WrapperContainer}
      >
        <Wrapper>
          <LogoLink to={paths.home()}>
            <Logo src={logo} alt="Localeum" />
          </LogoLink>

          <LinkWrapper>
            {upLg && (
              <Menu>
                <HashLink to={paths.features()} color={palette.white}>
                  Features
                </HashLink>
                <HashLink to={paths.howItWorks()} color={palette.white}>
                  How it works?
                </HashLink>
                <HashLink to={paths.integrations()} color={palette.white}>
                  Integrations
                </HashLink>
                <Link to={paths.pricing()} color={palette.white}>
                  Pricing
                </Link>
                {/* <DocsLink href={paths.docs()} color={palette.white}> */}
                {/*  Docs */}
                {/* </DocsLink> */}
              </Menu>
            )}
            {upLg && <LoginLink href={paths.login()}>Login</LoginLink>}
            <SignupLink href={paths.signup()}>Sign up</SignupLink>
            {!upLg && (
              <MenuIcon
                src={menu}
                alt="Menu"
                onClick={open ? onClose : onOpen}
              />
            )}
          </LinkWrapper>
        </Wrapper>

        {open && (
          <SubMenu>
            {/* <ButtonTryIt type="button">Try it free</ButtonTryIt> */}

            {/* <HashLink to={paths.forDevelopers()} color={palette.white}> */}
            {/*  For Developers */}
            {/* </HashLink> */}
            {/* <HashLink to={paths.forManagers()} color={palette.white}> */}
            {/*  For Managers */}
            {/* </HashLink> */}
            {/* <HashLink to={paths.forTranslators()} color={palette.white}> */}
            {/*  For Translators */}
            {/* </HashLink> */}
            {/* <HashLink to={paths.forStartups()} color={palette.white}> */}
            {/*  For Startups */}
            {/* </HashLink> */}

            <HashLink
              to={paths.features()}
              color={palette.white}
              onClick={() => {
                onClose()
              }}
            >
              Features
            </HashLink>
            <HashLink to={paths.howItWorks()} color={palette.white}>
              How it works?
            </HashLink>
            <HashLink to={paths.integrations()} color={palette.white}>
              Integrations
            </HashLink>
            <Link to={paths.pricing()} color={palette.white}>
              Pricing
            </Link>
            <Link to={paths.docs()} color={palette.white}>
              Docs
            </Link>
          </SubMenu>
        )}
      </Container>
      <FixHeight />
    </>
  )
}
